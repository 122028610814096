import Table from '@mui/material/Table';
import React, { useState,useEffect, useMemo} from 'react'; 
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import BodyDataTable from './BodyDataTable.jsx';




const DataTable = ({ response,columns,OnewQuery}) => {
 
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [hidenColumn,setHidenColumn]= useState([]);
    const COUNT= useMemo(() => response.total_count, [response.total_count]);
    const memoizedResponse = useMemo(() =>response, [response]);
  
    const memoizedColumns = useMemo(() => {
      if (hidenColumn.length > 0)
          return hidenColumn
       else return columns
      
      }, [columns,hidenColumn]);
    
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(columns[0].id);
    useEffect(() => {
        
         OnewQuery({
          limit: rowsPerPage,
          page: page,
          order_by:orderBy,
          order_dir:order,              
       });
      }, [rowsPerPage,page,orderBy,order]);

  

    const handleChangePage = (event, newPage) => {
     
      setPage(newPage);
    
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
     
    };

    const createSortHandler = (id,order) =>{
        if(order==='asc') setOrder('desc');
        else setOrder('asc');
        setOrderBy(id);
    }


    return (
     <>
      <TableContainer component={Paper} style={{ maxHeight: '750px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead key={1} style={{position: 'sticky', top: 0, zIndex: 1000, backgroundColor: 'white'}}>
            <TableRow>
            {memoizedColumns.map((column) => (
                 !column.hidden? <TableCell
                    key={column.id}
                  
                    style={ {minWidth: column.minWidth,backgroundColor:'white',color:'#212b36'} }
                   
                  >
                     <TableSortLabel  style={{ color:'rgb(7 56 107)' }}
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={ ()=>{createSortHandler(column.id,order)} }
                    >
                {column.label}
               
            </TableSortLabel>
                    
                  </TableCell>:<></>
                ))}
              <TableCell
                    key='function123'
                  
                    style={ {minWidth: '50px',backgroundColor:'white',color:'#212b36'} }
                   
                  ></TableCell>
            </TableRow>
          </TableHead>
          <BodyDataTable key={2} data={memoizedResponse.data} columns={memoizedColumns}></BodyDataTable>
          </Table>
          </TableContainer>

          <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={COUNT}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
  
        />
  
  </>
         
    );
  };


  export default DataTable