import React, { useState, useCallback, useEffect } from "react";

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
import Router from "./routes/routes";
// theme
import ThemeProvider from "./theme";
import { AuthContext } from "./context/auth-context";
import { DashboardDataContext } from "./context/dashData-context";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userAccess, setUserAccess] = useState(false);
  const [refreshed, setRefreshed] = useState(false);

  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
  }, []);

  const access = useCallback(() => {
    setUserAccess(true);
  });

  const accessRemove = useCallback(() => {
    setUserAccess(false);
  });

  const refresh = useCallback(() => {
    setRefreshed(!refreshed);
  });

  const authValue = {
    isLoggedIn: isLoggedIn,
    userPrivilege: userAccess,
    isReloaded: refreshed,
    login: login,
    logout: logout,
    access: access,
    accessRemove: accessRemove,
    refresh: refresh,
  };

  //-----------data context--------//

  const [total, setTotal] = useState(0);
  const [newThrivers, setNewThrivers] = useState(0);
  const [suspended, setSuspended] = useState(0);
  const [unsuspended, setUnsuspended] = useState(0);

  const addTotal = useCallback((data) => {
    setTotal(data);
  });
  const addnewThisWeek = useCallback((data) => {
    setNewThrivers(data);
  });
  const addsuspended = useCallback((data) => {
    setSuspended(data);
  });
  const addunsuspended = useCallback((data) => {
    setUnsuspended(data);
  });

  const dashDataValue = {
    total: total,
    newThisWeek: newThrivers,
    suspended: suspended,
    unsuspended: unsuspended,
    addTotal: addTotal,
    addnewThisWeek: addnewThisWeek,
    addsuspended: addsuspended,
    addunsuspended: addunsuspended,
  };

  return (
    <HelmetProvider>
      <BrowserRouter basename="/tams">
        <ThemeProvider>
          <AuthContext.Provider value={authValue}>
            <DashboardDataContext.Provider value={dashDataValue}>
              <Router />
            </DashboardDataContext.Provider>
          </AuthContext.Provider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
