import {
  InputLabel,
  TextareaAutosize,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import React from "react";
import { useState, useEffect } from "react";

const FormWrapper = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
  margin: "0 auto",
});

const SlackMessage = ({ onClose }) => {
  const roles = ["role 1", "role 2"];

  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchChannelList = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/slack/channels`
      );
      if (!response.ok) {
        throw new Error("Something went Wrong");
      }

      const data = await response.json();

      console.log(data);

      setChannels(data.data);

      setIsLoading(false);
    } catch (error) {
      setError(error.message); //message here is the msg set when throw error.
      setIsLoading(false); //done loading with error
    }
  };

  useEffect(() => {
    fetchChannelList();
  }, []);

  const handleChannelChange = (evt) => {
    console.log(evt.target.value);
    setSelectedChannel(evt.target.value);
  };

  const handleMessageChange = (evt) => {
    setMessage(evt.target.value);
  };

  const sendMessageSlack = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/slack/send-message`,
        {
          method: "POST",
          // body: data,
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error While trying to send message");
      }

      const responseData = await response.json(); //response from server
      setIsSubmitted(true);

      setIsSubmitting(false);

      setMessage(responseData.message);
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    sendMessageSlack({
      message: message,
      channelId: selectedChannel,
    });
    setMessage("");
    setSelectedChannel("");
  };

  return (
    <>
      {!isSubmitted ? (
        <FormWrapper onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <InputLabel id="channel-label">Channel</InputLabel>
            <Select
              labelId="channel-label"
              id="channel"
              value={selectedChannel}
              onChange={handleChannelChange}
              required
            >
              {channels.map((channel, index) => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginTop: "1rem" }}>
            <TextField
              multiline
              rows={12}
              label="Text Area"
              placeholder="Enter your text here"
              variant="outlined"
              onChange={handleMessageChange}
              // value={message}
            />
          </FormControl>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isSubmitting ? true : false}
          >
            Send Message
          </LoadingButton>
        </FormWrapper>
      ) : (
        <Box>
          <Typography>{message}</Typography>
        </Box>
      )}
    </>
  );
};

export default SlackMessage;
