import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Box,

} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import roles from "../../../_mock/roles";
import teams from "../../../_mock/teams";
import FormConfirmation from "./FormConfirmation";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const FormWrapper = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "0.6rem",
  // maxWidth: "400px",
  maxHeight: "90vh",
  margin: "0 auto",
});

function UserFormMain({
  type,
  onClose,
  onClosePop,
  act,
  id_user,
  fname,
  lname,
  position,
  team,
  shortcode,
  emailAdd,
  temail,
  rolex,
  stat,
  create_d,
  end_d,
  onBoar,
  activityRep,
  onChangeStep
}) {
  //new user inputs

  const [active, setActive] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [userPosition, setUserPosition] = React.useState("");
  const [userTeam, setUserTeam] = React.useState("");
  const [shortCode, setShortCode] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [thriveEmail, setThriveEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [create_date, setCreateDate] = React.useState(dayjs().format('YYYY-MM-DD'));
  const [end_date, setEndDate] = React.useState(dayjs().add(6, 'month').format('YYYY-MM-DD'));
  const [status, setStatus] = React.useState(true);
  const [onBoarding, setOnBoarding] = React.useState(true);
  const [activityReport, setActivityReport] = React.useState(true);


  //form submittion
  const [isSubmitting, setIsSubmitting] = useState(false); // button effect on submittion
  const [isFormSubmitted, setSubmitted] = useState(false); //form message confirmation
  const [message, setMessage] = useState(true);
  //variables for pre-filled inputs to edit users
  const pre_act = act;
  const pre_fName = fname;
  const pre_id_user = id_user;
  const pre_lName = lname;
  const pre_position = position;
  const pre_team = team;
  const pre_shortcode = shortcode;
  const pre_emailAdd = emailAdd;
  const pre_thriveEmailAdd = temail;
  const pre_rolex = rolex;
  const pre_create_d=new Date(create_d);
  const pre_end_d=new Date(end_d);
  const pre_stat = stat;
  const pre_onBoar = onBoar;
  const pre_activityRep = activityRep;
 
  React.useEffect(() => {
 
    setFirstName(fname);
    setLastName(lname);
    setEmail(emailAdd);
    setRole(rolex);
    setShortCode(shortcode);
    setUserTeam(team);
    setUserPosition(position);
    setThriveEmail(temail);
    setCreateDate(create_d);
    setEndDate(end_d);
 }, [fname,lname,emailAdd,shortcode,rolex,team,position,temail,create_d,end_d] );
  //handling inputs

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

 

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    setShortCode(event.target.value.slice(0, 8));
  };

  const handlePositionChange = (event) => {
    setUserPosition(event.target.value);
  };

  const handleTeamChange = (event) => {
    setUserTeam(event.target.value);
  };

  const handleThriveEmail = (event) => {
    setThriveEmail(event.target.value);
  };

  const handleDateChange = (date) => {
    setCreateDate(dayjs(date).format('YYYY-MM-DD'));
    
  };
  const handleEndDateChange = (date) => { 
    setEndDate(dayjs(date).format('YYYY-MM-DD'));
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const new_user = {
      active:active,
      first_name:firstName,
      last_name:lastName,
      position:userPosition,
      create_date:create_date,
      end_date:end_date,
      team:userTeam,
      cod_role:shortCode,
      personal_email: email,
      thrive_email:thriveEmail,
      status:status,
      role_name:role,
      onChangeStep
    };

    const editUser = {
      active: active || pre_act,
      first_name: firstName || pre_fName,
      name: firstName || pre_fName,
      last_name: lastName || pre_lName,
      position: userPosition || pre_position,
      team: userTeam || pre_team,
      cod_role: shortCode || pre_shortcode,
      personal_email: email || pre_emailAdd,
      thrive_email: thriveEmail || pre_thriveEmailAdd,
      create_date:create_date || pre_create_d,
      end_date:end_date || pre_end_d,
      role_name: role || pre_rolex,
      status: status || pre_stat,
      onBoarding: onBoarding || pre_onBoar,
      activity_report: activityReport || pre_activityRep,
      id_user: pre_id_user
    };
    let json_user={};
    switch(type){
      case "create":
        json_user={...new_user,
          part_one:false
         };
         onChangeStep(json_user);
        break;
      case "edit":
        json_user={...editUser,
                        part_one:false
                       };
        onChangeStep(json_user);
        break;
        default:
          break;
    }
  
  };


  const handleKeyUp_firtname = (event)=>{
    if(type==='create'){
    
    let thrive_email= firstName+'.'+lastName.charAt(0)+'@strive2thrive.earth';
    setThriveEmail(thrive_email.toLowerCase());
    }
  }

  const handleKeyUp_lastname = (event)=>{
    if(type==='create'){
    
   
    let thrive_email= firstName+'.'+lastName.charAt(0)+'@strive2thrive.earth';
    setThriveEmail(thrive_email.toLowerCase());
    }
  }



  return (
    <>
      {isFormSubmitted ? (
        <FormConfirmation
          action={type}
          onClose={onClose}
          message={message}
          submitted={setSubmitted}
          onClosePop={onClosePop}
          from={"usersPage"}
        />
      ) : (
        <FormWrapper onSubmit={handleSubmit}>
             
      
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom:'5px' }}>
          <TextField
              id="first-name" onKeyUp={handleKeyUp_firtname}
              label="First Name"
              value={fname ? fname : firstName}
              defaultValue={fname || ''}
              onChange={handleFirstNameChange}
              // fullWidth
              required
              disabled={type === "create" || type === "edit" ? false : true}
              style={{ width: '48%', marginRight: '4%' }}
            />
            <TextField
              id="last-name" onKeyUp={handleKeyUp_lastname}
              label="Last Name"
              defaultValue={lname || ''}
              onChange={handleLastNameChange}
              // fullWidth
              required
              disabled={type === "create" || type === "edit" ? false : true}
              style={{ width: '48%'}}
            />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom:'5px' }}>
            <TextField
              id="email"
              label="Personal Email"
              type="email"
              defaultValue={emailAdd || ''}
              onChange={handleEmailChange}
              // fullWidth
              style={{ width: '48%', marginRight: '4%' }}
              required
              disabled={type === "create" || type === "edit" ? false : true}
            />
             <TextField
                id="thrive_email"
                value={temail?temail:thriveEmail}
                label="Thrive Email"
                onChange={handleThriveEmail}
                type="email"
                defaultValue={temail?temail:''}
                disabled={true}
                style={{ width: '48%'}}
              />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom:'5px' }}>
            <TextField
              id="position"
              label="Position"
              type="text"
              defaultValue={position ? position : ""}
              onChange={handlePositionChange}
              required
              disabled={type === "create" || type === "edit" ? false : true}
             style={{ width: '100%'}} 
            />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom:'5px' }}>
          
             
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ display: 'flex' }}>
              <DatePicker  
                    
                      defaultValue={create_d?dayjs(create_d):dayjs()}
                      label="start date"
                      onChange={handleDateChange}
                      style={{display:'flex'}}
                     
                      />
                      </div>
            </LocalizationProvider>  
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex' }}>
              <DatePicker  
                      defaultValue={end_d?dayjs(end_d):dayjs().add(6, 'month')}
                      label="end date"
                      onChange={handleEndDateChange}
                      style={{display:'flex'}}
                    
                      />
                      </div>
            </LocalizationProvider>  
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom:'5px' }}>
            <FormControl style={{ width: '48%', marginRight:'4%'}}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                // value={rolex ? rolex : role}
                defaultValue={rolex ? rolex : ""}
                onChange={handleRoleChange}
                required
                disabled={type === "create" || type === "edit" ? false : true}
              
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
            <FormControl style={{ width: '48%'}}>
              <InputLabel id="team-label">Team</InputLabel>
              <Select
                labelId="team-label"
                id="team"
                // value={rolex ? rolex : role}
                defaultValue={team ? team : ""}
                onChange={handleTeamChange}
                required
                disabled={type === "create" || type === "edit" ? false : true}
                
              >
                {/* change below to list of teams */}
                {teams.map((team) => (
                  <MenuItem key={team} value={team}>
                    {team}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
        
         <Divider style={{MarginBottom:'20px'}}/>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="error"
              onClick={(evt) => {
                onClose();
              
              }}
              style={{ backgroundColor: '#bf0606', borderRadius:'0px' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting ? true : false}
              style={{ backgroundColor: '#07386b', borderRadius:'0px' }}
            >
              Next
            </LoadingButton>
          </Box>
        </FormWrapper>
      )}
    </>
  );
}

export default UserFormMain;
