import { Typography } from "@mui/material";
import React from "react";

const SettingsPage = () => {
  return (
    <Typography variant="h4" color={"#778692"} gutterBottom>
      General Settings
    </Typography>
  );
};

export default SettingsPage;
