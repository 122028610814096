import React, { useState,useEffect,useMemo} from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
 const BodyDataTable= ({data, columns})=>{
  
   const [html_data,setHtmlData] = useState(null);
   const memorizedData = useMemo(() =>data, [data]);
   const memorizedColumns = useMemo(() =>columns, [columns]);
   useEffect(() => {
   
    setHtmlData(build_row());
   
  }, [data,columns]);




   const build_row= ( )=>{
     
   
      if (!memorizedData || memorizedData.length === 0) {
  
        return (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>No data available</TableCell>
            </TableRow>
          </TableBody>
        );
      }
    
     
    
      return (
        <TableBody>
          {memorizedData.map((row) => (
            <TableRow key={row.id_user}>
              {memorizedColumns.map((column) => {
                return (
                  !column.hidden && (
                    <TableCell
                      key={`${row.id_user}-${column.id}`} // Clave única para TableCell
                      style={{
                        width: '100px',
                        maxWidth: '200px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {column.type === 'string' && row[column.id]}
                      {column.type === 'boolean' && (
                        <span style={{ color: row[column.id] ? 'red' : 'blue' }}>
                          {row[column.id] ? 'Sí' : 'No'}
                        </span>
                      )}
                      {column.type === 'percent' && (
                        parseFloat(row[column.id]).toFixed(2) + '%'
                      )}
                    </TableCell>
                  )
                );
              })}
              <TableCell
                key={'$tams' + row.id_user}
                style={{
                  width: '100px',
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                <IconButton>
                  <EditIcon style={{ fontSize: '20px', color: 'rgb(7 56 107)' }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
      
  
      
   }


   
     
 

    return html_data;
      
 }   

 export default BodyDataTable