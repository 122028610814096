import React, { useState,useEffect,useMemo} from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import ModalUser from '../../../modal/modalUser';
 const BodyDataTable= ({data, columns})=>{
  
   const [open_modal,setOpenModal]=React.useState(false);
   const [user,setUser]=React.useState({});
   const [html_data,setHtmlData] = useState(null);
   const memorizedData = useMemo(() =>data, [data]);
   const memorizedColumns = useMemo(() =>columns, [columns]);
   useEffect(() => {
   
    setHtmlData(build_row());
   
  }, [data,columns,open_modal,user]);

  const openModal=(user_id)=>{
    
    setUser(user_id);
    setOpenModal(open_modal=>!open_modal);
  }


   const build_row= ( )=>{
     
   
      if (!memorizedData || memorizedData.length === 0) {
  
        return (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>No data available</TableCell>
            </TableRow>
          </TableBody>
        );
      }
    
     
    
  
      return (
       <>
              <TableBody>
            { memorizedData.map((row) => (
            <TableRow key={row.id_user}>
              {memorizedColumns.map((column)=>{
               return (!column.hidden?<TableCell key={column.id} style={{ width: '100px', maxWidth:'200px',whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow: 'hidden', }} >{row[column.id]}</TableCell>:<></>);
              }
            )}
            <TableCell key={"$tams"+row.id_user} style={{ width: '100px',maxWidth:'200px',whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow: 'hidden', }}>  
            <IconButton onClick={() => openModal(row)}>
                <EditIcon style={{ fontSize: '20px', color:'rgb(7 56 107)' }} />
               </IconButton>
            
            </TableCell>
            </TableRow>
            ))}
           
         
        </TableBody>
        <ModalUser part_one={true} open={open_modal} type="edit" user_id={user} onHandleClose={(value)=>{setOpenModal(value)}}></ModalUser>
        </>
       

       );
     
  
      
   }


   
     
 

    return html_data;
      
 }   

 export default BodyDataTable