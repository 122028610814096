import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  userPrivilege: false,
  isReloaded: false,
  login: () => {},
  logout: () => {},
  access: () => {},
  accessRemove: () => {},
  refresh: () => {},
});
