const roles = [

    "[204006] Volunteer Backend Developer",
    "[204009] Volunteer Business Development Lead",
    "[204010] Volunteer Community Builder",
    "[204011] Volunteer Content Creator",
    "[204012] Volunteer Copyeditor",
    "[204013] Volunteer Data Analyst",
    "[162842] Volunteer Database Administrator",
    "[204014] Volunteer Digital Marketer",
    "[204015] Volunteer Documentation Coordinator",
    "[204016] Volunteer Educational Research Coordinator",
    "[204017] Volunteer Events Coordinator",
    "[204018] Volunteer Experienced Fundraiser/Grant Writer",
    "[204019] Volunteer Front-end Developer/UX",
    "[204020] Volunteer Full Stack Web Developer",
    "[204021] Volunteer Google Apps Developer",
    "[204022] Volunteer Graphic Designer",
    "[204023] Volunteer Human Resources Coordinator",
    "[204026] Volunteer IT Support",
    "[204027] Volunteer LinkedIn Personal Branding",
    "[204028] Volunteer Media Publicist",
    "[204029] Volunteer Multimedia Animation Designer",
    "[170999] Volunteer Partnerships Developer",
    "[204030] Volunteer Podcast/Webinar Host",
    "[204031] Volunteer Public Relations Developer",
    "[204032] Volunteer Social Media Lead",
    "[204033] Volunteer Storyteller & Scriptwriter",
    "[204035] Volunteer Systems Administrator",
    "[204036] Volunteer Team Assistant",
    "[204038] Volunteer Team Builder",
    "[204040] Volunteer Thrivability Researcher",
    "[204041] Volunteer Trainer",
    "[204042] Volunteer Video Editor",
    "[204043] Volunteer Voiceover Artist",
    "[204044] Volunteer WordPress Developer",
    "[999999] Advisor",
];

export default roles;
