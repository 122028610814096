import { Typography, TextField, Box } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";

const FormWrapper = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
  margin: "0 auto",
});

const SlackNewUser = ({ onClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [thriveEmail, setThriveEmail] = useState("");

  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFirtNameChange = (evt) => {
    console.log(evt.target.value);
    setFirstName(evt.target.value);
  };

  const handleLastNameChange = (evt) => {
    console.log(evt.target.value);
    setLastName(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    console.log(evt.target.value);
    setThriveEmail(evt.target.value);
  };

  const sendInviteSlack = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/slack/send-invite`,
        {
          method: "POST",
          // body: data,
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error While trying to send message");
      }

      const responseData = await response.json(); //response from server
      setIsSubmitted(true);

      setIsSubmitting(false);

      setMessage(responseData.message);
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    sendInviteSlack({
      firstName,
      lastName,
      thriveEmail,
    });
    setFirstName("");
    setLastName("");
    setThriveEmail("");
  };

  return (
    <>
      {!isSubmitted ? (
        <FormWrapper onSubmit={handleSubmit}>
          <TextField
            id="first-name"
            label="First Name"
            // value={fname ? fname : firstName}
            //   defaultValue={fname}
            onChange={handleFirtNameChange}
            fullWidth
            required
          />
          <TextField
            id="last-name"
            label="Last Name"
            // value={lname ? lname : lastName}
            //   defaultValue={lname}
            onChange={handleLastNameChange}
            fullWidth
            required
          />
          <TextField
            id="email"
            label="Thrive Email"
            type="email"
            // value={emailAdd ? emailAdd : email}
            //   defaultValue={emailAdd}
            onChange={handleEmailChange}
            fullWidth
            required
          />

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isSubmitting ? true : false}
          >
            Send Invitation
          </LoadingButton>
        </FormWrapper>
      ) : (
        <Box>
          <Typography>{message}</Typography>
        </Box>
      )}
    </>
  );
};

export default SlackNewUser;
