import { Button, CircularProgress, Divider, IconButton, Switch, TextField } from "@mui/material";
import KeyIcon from '@mui/icons-material/Key';
import React from "react";
import {WebmailCreated, suspendWebmail,unsuspendWebmail } from "../../../api/webmail/webmail";
import {fetchEditUser,fetchInsertUser} from "../../../api/users/fetchUsersList";
import Swal from 'sweetalert2';
import './sweetAlertCustom.css';
const FormWebmail=(props)=>{
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const [loading, setLoading] = React.useState(false);
const [switchStates, setSwitchStates] = React.useState({
    incomingSuspended: false,
    sendSuspended: false,
    loginSuspended: false,
    allSuspended: false,
  });
const [password, setPassword] = React.useState('');
const type=props.type;
React.useEffect(() => {
  generatePassword();
},[props.user_data]);

const handleFourthSwitchToggle = () => {

    setSwitchStates((prevStates) => ({
      ...prevStates,
      incomingSuspended: !prevStates.allSuspended,
      sendSuspended: !prevStates.allSuspended,
      loginSuspended: !prevStates.allSuspended,
      allSuspended: !prevStates.allSuspended,
    }));
  };

const handleBack=()=>{
 
    const user_data={
      data:props.user_data,
      part_one :true,
    }
   
    props.handleBack(user_data);
}

const confirmButton= async()=>{


  Swal.fire({
    title: props.type,
    text: 'Are you certain you would like to '+props.type+' the information about this user?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: props.type.toUpperCase(),
    cancelButtonText:'CANCEL'
    }).then(async(result) => {
      if (result.isConfirmed) {
       if(props.type==='edit')  await handleEdit();
       else await handleCreate();
      }
      
    });

    const swalContainer = document.querySelector('.swal2-container');
    if (swalContainer) {
     swalContainer.style.zIndex = '10000'; // Set your desired z-index value
    }

    const iconElement = document.querySelector('.swal2-icon');
    if (iconElement) {
      iconElement.style.fontSize = '12px'; // Change font size
    }

     // Change the button color and border radius after dialog is displayed
     const confirmButton = document.querySelector('.swal2-confirm');
     if (confirmButton) {
       confirmButton.style.backgroundColor = '#07386b'; // Change button background color
       confirmButton.style.borderRadius = '0px'; // Change button border radius
     }

       // Change the button color and border radius after dialog is displayed
       const cancelButton = document.querySelector('.swal2-cancel');
       if (cancelButton) {
         cancelButton.style.backgroundColor = '#bf0606'; // Change button background color
         cancelButton.style.borderRadius = '0px'; // Change button border radius
       }
}

const handleEdit= async()=>{
   
   
     
    
    setLoading(true);
     let user_data={
      thrive_email:props.user_data.thrive_email,
      id_user:props.user_data.id_user,
      settings:switchStates
     }
     
     await fetchEditUser(props.user_data);

     if(switchStates.loginSuspended || switchStates.allSuspended){
     const response= await suspendWebmail(user_data);
     setLoading(response.error);

    }else{ 
      const response= await unsuspendWebmail(user_data);
      setLoading(response.error);
     
    }
   props.onClose();
}

const handleCreate= async()=>{
  setLoading(true);
  const response_insert= await fetchInsertUser(props.user_data);
  
  let user_data={
    id_user:response_insert.id_user,
    user_data:props.user_data,
    settings:switchStates,
    password
   }
   if(!response_insert.error)  await WebmailCreated(user_data);
  
  setLoading(false);
  props.onClose();
}

const generatePassword = () => {
  const length = 12; // Length of the password
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=<>?'; // Character set for the password
  let newPassword = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    newPassword += charset[randomIndex];
  }
  setPassword(newPassword);
};





return(
<><h3 style={{color:'#07386b'}}>WebMail invitation</h3>
<Divider style={{marginBottom:'20px'}}/>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
<TextField
          id="first-name"
          label="First Name"
          defaultValue={props.user_data.first_name + ' ' + props.user_data.last_name}
          required
          disabled={true}
          style={{ width: '48%', marginRight: '4%' }}
        />
        <TextField
          id="email"
          label="Email"
          defaultValue={props.user_data.thrive_email}
          required
          disabled={true}
          style={{ width: '48%' }}
        />
        </div>
        <Divider style={{marginBottom:'20px'}}/>
        { type==='create'?(
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <TextField
        label="password"
        variant="outlined"
        fullWidth
        value={password}
      />
      <IconButton onClick={generatePassword}>
        <KeyIcon color="#07386b" />
      </IconButton>
        </div>):null}
   {type==='edit'?(    
  <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%',marginTop:'16px' }}>
  <div style={{ textAlign: 'center' }}>
      <label style={{ marginBottom: '8px' }}>Suspend Account</label>
      <Switch {...label} checked={switchStates.allSuspended} onChange={handleFourthSwitchToggle}  />
    </div>
    <div style={{ textAlign: 'center' }}>
      <label style={{ marginBottom: '8px' }}>Incoming Email</label>
      <Switch {...label} checked={switchStates.incomingSuspended} onChange={() => setSwitchStates((prevStates) => ({ ...prevStates, incomingSuspended: !prevStates.incomingSuspended }))} />
    </div>
    <div style={{ textAlign: 'center' }}>
      <label style={{ marginBottom: '8px' }}>Sending Email</label>
      <Switch {...label} checked={switchStates.sendSuspended}  onChange={() => setSwitchStates((prevStates) => ({ ...prevStates, sendSuspended: !prevStates.sendSuspended }))}/>
    </div>
    <div style={{ textAlign: 'center' }}>
      <label style={{ marginBottom: '8px' }}>Login</label>
      <Switch {...label} checked={switchStates.loginSuspended} onChange={() => setSwitchStates((prevStates) => ({ ...prevStates, loginSuspended: !prevStates.loginSuspended }))}/>
    </div>
    
  </div>):null}
  </div>

  {loading?( 
  
<div style={{ display: 'flex', justifyContent: 'center', width: '100%',height: '68px'}}>
<CircularProgress />
</div> 
 ): null }
  <Divider style={{marginBottom:'20px'}}/>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%'}}>
        <Button disabled={loading} variant="contained" style={{ backgroundColor: '#bf0606',    borderRadius:'0px' }} onClick={handleBack}>
          Back
        </Button>
        { type==='edit'?(
        <Button disabled={loading} variant="contained" style={{ backgroundColor: '#389382', borderRadius:'0px' }} onClick={confirmButton}>
          Edit
        </Button>):(<Button disabled={loading} variant="contained" style={{ backgroundColor: '#07386b', borderRadius:'0px'}} onClick={confirmButton}>
          Create
        </Button>)
        }
      </div>
</div>

</>
);
    
}
export default FormWebmail;
