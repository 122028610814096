export const fetchUsersList = async (body={}) => {
  try {
  
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/users/user_list`,{
        headers: {
          'Content-Type': 'application/json',
        },
      method: 'POST',
      body:JSON.stringify(body)
      }
    );

    if (!response.ok) {
      throw new Error("Something went Wrong");
    }

    const data = await response.json();

    // console.log(data);

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchEditUser = async(user_data)=>{
  try {
  
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/users/edit_user`,{
        headers: {
          'Content-Type': 'application/json',
        },
      method: 'POST',
      body:JSON.stringify(user_data)
      }
    );

    if (!response.ok) {
      throw new Error("Something went Wrong");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
  }

}


export const fetchInsertUser = async(user_data)=>{
  try {
  
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/users/insert_user`,{
        headers: {
          'Content-Type': 'application/json',
        },
      method: 'POST',
      body:JSON.stringify(user_data)
      }
    );

    if (!response.ok) {
      throw new Error("Something went Wrong");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
  }

}