import * as React from 'react';
import {Modal,Box, Divider, Backdrop } from '@mui/material';
import './model_filter.css';
import UserFormMain from '../forms/UserFormMain';
import FormWebmail from '../forms/FormWebmail';
import dayjs from 'dayjs';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const ModalUser=(props)=>{
  const [open_modal,setOpenModal] = React.useState(props.open);
  const [user_data,setUserData]=React.useState(props.user_id);
  const [part_one,setPartOne]= React.useState(props.part_one);
  const [web_mail,setWebMail]= React.useState(false);
  
  

  React.useEffect(() => {
    
    if(!part_one) setWebMail(true);
    else setWebMail(false);
    setOpenModal(props.open);
  }, [props.open,part_one,web_mail]);
 
  React.useEffect(() => {
    //setPartOne(true);
    setUserData(props.user_id);
    
  }, [props.user_id]);

  const handleClose = (event) => {
    event.preventDefault();
    // No need to use local state if using the prop directly
    // setOpenModal(false);
    // Trigger parent's onClose function or update the parent state accordingly
    props.onHandleClose(false);
    //setOpenModal(false);
  };
  
  const onClose = ()=>{
    setPartOne(true);
    setOpenModal(false);
    props.onHandleClose(false);
  }
  
  return(
    <Modal
      open={open_modal}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      BackdropComponent={Backdrop} BackdropProps={{ invisible: false }}
    >
      
      <Box className="class_modal" sx={{ ...style, width: '60%' }}>
      <h2 id="child-modal-title" className='class_title'>User Form</h2>
        <p id="child-modal-description"></p>
        <Divider style={{marginBottom:'20px'}}/>
       
         {part_one ? ( props.type ==='edit'?(
       <UserFormMain  type={props.type} onClose={onClose}
            act={false}
            fname={user_data.first_name}
            shortcode={user_data.cod_role}
            id_user={user_data.id_user}
            lname={user_data.last_name}
            position={user_data.position}
            team={user_data.team}
            create_d={user_data.create_date}
            emailAdd={user_data.personal_email}
            temail={user_data.thrive_email}
            rolex={user_data.role_name} 
            onChangeStep={(value)=>{setUserData(value); setPartOne(value.part_one)}}
            ></UserFormMain>
         ):(<UserFormMain  type={props.type} onClose={onClose}
            act={false}
            fname={Object.keys(user_data).length !== 0?user_data.first_name:''}
            shortcode={Object.keys(user_data).length !== 0?user_data.cod_role:''}
            id_user={Object.keys(user_data).length !== 0?user_data.id_user:''}
            lname={Object.keys(user_data).length !== 0?user_data.last_name:''}
            position={Object.keys(user_data).length !== 0?user_data.position:''}
            team={Object.keys(user_data).length !== 0?user_data.team:''}
            create_d={Object.keys(user_data).length !== 0?user_data.create_date:dayjs().format('YYYY-MM-DD')}
            end_d={Object.keys(user_data).length !== 0?user_data.end_date:dayjs().add(6, 'month').format('YYYY-MM-DD')}
            emailAdd={Object.keys(user_data).length !== 0?user_data.personal_email:''}
            temail={Object.keys(user_data).length !== 0?user_data.thrive_email:''}
            rolex={Object.keys(user_data).length !== 0?user_data.role_name:''} 
          onChangeStep={(value)=>{setUserData(value); setPartOne(value.part_one)}}
          ></UserFormMain>)
       ):null }
       {web_mail?(
        <FormWebmail user_data={user_data} onClose={onClose} type={props.type}  handleBack={(value)=>{ setPartOne(value.part_one); console.log("user_form: ",value.data);setUserData(value.data);}}></FormWebmail>
       ):null}
      
      </Box>
    </Modal>

  );

}

export default ModalUser;