import React, { useState,useMemo } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ModalUser from '../../../modal/modalUser';
const ToolbarDataTable=(props)=>{
    const {count,onNewCategory,onOpenModal,onOpenModalColumns}=props;
    const memorizeCount= useMemo(()=>count,[count]);
    const [inputCategorie,setInputCategorie]=useState('');
    const [modal,setChange]=useState(false);
    const [modal2,setChange2]=useState(false);
    const [modal3,setChange3]=useState(false);
    const onInputChange = ({ target } ) => {
      setInputCategorie( target.value );
    }

    const onSubmit = ( event ) => {
     
      event.preventDefault();
      if( inputCategorie.trim().length <= 1) return;

      
      onNewCategory( inputCategorie.trim() );
  }
  
 const  handleDownloadExcel = async () => {
    try {
      // Make a GET request to the server endpoint
      const response = await fetch( `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/users/download_excel?count=${memorizeCount}`);

      // Check if the request was successful (status code 200)
      if (response.ok) {
        const excelBlob = await response.blob();

        // Create a Blob URL
        const blobUrl = URL.createObjectURL(excelBlob);

        // Create a link element and trigger the download
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = 'thrive_users_list.xlsx';
        a.click();

        // Release the Blob URL
        URL.revokeObjectURL(blobUrl);
      } else {
        console.error('Failed to download Excel file');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  
  const Modal_filter_hidden= ()=>{
    setChange((modal)=>!modal);
    onOpenModal(modal);
  } 

  const Modal_filter_columns = ()=>{
        setChange3((modal3)=>!modal3);
        onOpenModalColumns(modal3);
  }

  const Modal_user=()=>{
    setChange2(true);
  }

    return(
      <>
      <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '70%' }}
    >
      
      
      <IconButton onClick={handleDownloadExcel} sx={{ p: '10px' }} aria-label="menu">
        <CloudDownloadIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton onClick={Modal_filter_hidden} sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton sx={{ p: '10px' }} onClick={Modal_filter_columns} aria-label="menu">
      <FilterListIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={inputCategorie}
        placeholder="Search User Tams"
        onKeyPress={handleKeyPress}
        onChange={onInputChange}
       
      />
      <IconButton type="button" sx={{ p: '10px' }} onClick={onSubmit} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 2.5 }} orientation="vertical" />
      <IconButton type="button" sx={{ p: '10px' }} onClick={Modal_user} aria-label="search">
        <PersonAddAltIcon style={{color:'rgb(19 104 4)' }} />
      </IconButton>
    </Paper>
    
    <ModalUser  open={modal2} type="create" part_one={true} user_id={{}} onHandleClose={()=>{ setChange2(false)}}></ModalUser>
       
    </>
   
    );
}

export default ToolbarDataTable;