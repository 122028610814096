import React, { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
// components
import Iconify from "../../../components/iconify";

import { AuthContext } from "../../../context/auth-context";


// ----------------------------------------------------------------------

export default function LoginForm() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState(false);

  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [password, setPassword] = useState(localStorage.getItem('password') || '');

  const loginAuth = async (credentials) => {
  
    setError(null);
    try {

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/users/login`,
        {
          method: "POST",
          body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error While trying to send newsletter");
      }

      const responseData = await response.json(); //response from server
     
      const access= responseData.data.access;

      const login= responseData.data.data;
    

      if (access) {
        if (login.user_role=== "Admin") {
          setError(false);
          auth.access();
          auth.login();
          navigate("/dashboard", { replace: true });
        } else {
          setError(false);
          auth.login();
          navigate("/dashboard", { replace: true });
        }

     
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);
        
      } else {
        setUsername("");
        setPassword("");
        setError(true);
        console.log("Wrong credentials");
      }
    } catch (error) {
    
      setError(true);
      console.log(error);
    }
  };

  const emailInputHandler = (event) => {
    setUsername(event.target.value);
  };
  const passwordInputHandler = (event) => {
    setPassword(event.target.value);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    const credentials = {
      username: username,
      password: password,
    };
  
    await loginAuth(credentials);
  };

  React.useEffect(() => {
    // Verificar si ya hay una sesión iniciada al cargar la página
    if (localStorage.getItem('username') && localStorage.getItem('password')) {
      const storedUsername = localStorage.getItem('username');
      const storedPassword = localStorage.getItem('password');

      // Intentar iniciar sesión automáticamente con las credenciales almacenadas
      loginAuth({ username: storedUsername, password: storedPassword });
    }
  }, []); 

  return ( 
    <>
      <form onSubmit={authSubmitHandler}>
        <Stack spacing={3}>
          {error ? (
            <Alert severity="error">
              Invalid Credentials - Please contact support!
            </Alert>
          ) : (
            ""
          )}
          <TextField
            name="username"
            label="Username"
            value={username}
            onChange={emailInputHandler}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={passwordInputHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <Checkbox name="remember" label="Remember me"/> */}
          <Link variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // onClick={handleClick}
        >
          Login
        </LoadingButton>
      </form>
    </>
  );
}
