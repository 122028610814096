// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/tams/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

export const navConfigSuperadmin = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: icon("ic_analytics"),
  },
  {
    title: "Members",
    path: "/dashboard/members",
    icon: icon("ic_user"),
  },
  {
    title: "Webmail",
    path: "/dashboard/webmail",
    icon: icon("ic_mail"),
  },
  // {
  //   title: "Slack",
  //   path: "/dashboard/slack",
  //   icon: icon("ic_slack"),
  // },
  // {
  //   title: "Wordpress",
  //   path: "/dashboard/wordpress",
  //   icon: icon("ic_wordpress"),
  // },
  {
    title: "Mailman",
    path: "/dashboard/mailman",
    icon: icon("ic_newsletter"),
  },
  {
    title: "Settings",
    path: "/dashboard/settings",
    icon: icon("ic_settings"),
  },
];

export const navConfiguser = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: icon("ic_analytics"),
  },
  {
    title: "Members",
    path: "/dashboard/members",
    icon: icon("ic_user"),
  },
  {
    title: "Mailman",
    path: "/dashboard/mailman",
    icon: icon("ic_newsletter"),
  },
];
