import { createContext } from "react";

export const DashboardDataContext = createContext({
  total: 0,
  newThisWeek: 0,
  suspended: 0,
  unsuspended: 0,
  addTotal: (data) => {},
  addnewThisWeek: (data) => {},
  addsuspended: (data) => {},
  addunsuspended: (data) => {},
});
