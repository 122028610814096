import React from "react";
import { Helmet } from "react-helmet-async";
import { WebMailList } from "../../api/webmail/webmail";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DataTable from "../../components/shareComponent/dataTableComponent/dataTable_main";
// @mui
import {
  Card,
} from "@mui/material";
import ToolbarDataTable from "../../components/shareComponent/dataTableComponent/toolbar_data_table";
import ModalHider from "../../components/shareComponent/dataTableComponent/modalComponents/modal_hider";
import Modal_filter_columns from "../../components/shareComponent/dataTableComponent/modalComponents/modal-filter-form";


export default function WebMailPage() {
    
  const [columns,setColumns] = React.useState([
 

    {
        id: 'email',
        label: 'Email',
        minWidth: 170,
        align: 'right',
        type:'string',
        hidden:false
       
      },
    {
      id: 'humandiskquota',
      label: 'Disk Quota',
      minWidth: 170,
      align: 'right',
      type:'string',
      hidden:false
     
    },
    {
      id: 'diskusedpercent_float',
      label: 'Disk Percent',
      minWidth: 170,
      align: 'right',
      type:'percent',
      hidden:false
     
    },
    {
        id: 'humandiskused',
        label: 'Disk Used',
        minWidth: 170,
        align: 'right',
        type:'string',
        hidden:false
       
      },
      {
        id: 'suspended_login',
        label: 'Suspend Account',
        minWidth: 170,
        align: 'right',
        type:'boolean',
        hidden:false
       
      },
      {
        id: 'suspended_incoming',
        label: 'Suspend Incoming',
        minWidth: 170,
        align: 'right',
        type:'boolean',
        hidden:false
       
      }
  ]);
  const [response, setResponse] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [open_modal,setOpenModal]=React.useState(false);
  const [open_modal_columns,setOpenModalColumns]=React.useState(false);
  const fetch= async(query={})=>{
    try{
      const response= await WebMailList(query);
      setResponse(response);
      setLoading(false);
    }catch (error) {
        console.error('Error fetching data:', error);
      } 
  }
    React.useEffect(() => {
      
      setLoading(true);
       fetch();
     }, [columns]);

     const handleColumnVisibilityChange = (index) => {
      setColumns((prevColumns) => {
        const newColumns = [...prevColumns];
        newColumns[index].hidden = !newColumns[index].hidden;
        return newColumns;
      });
    };
    
    const search_query= async(value)=>{
      let query={
        page:0,
        filter:1,
        filter_term_0:value,
        filter_column_0:'user',
      }
      await fetch(query);
    }
    return (
        <>
        <Helmet>
        <title> Webmail List | TAMS </title>
      </Helmet>
    
      <Card>
    {loading ? (
           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '650px', width: '100%'  }}>
           <CircularProgress size={80} />
         </Box>
      ) : (
        <div>
        <ToolbarDataTable api={"email"} onModaluser={(value)=>{console.log(value)}} onOpenModal={(value)=>{ setOpenModal((value) => !value)}}  onOpenModalColumns={(value)=>{ setOpenModalColumns((value) => !value)}} count={response.total_count} onNewCategory={(value)=>{ search_query(value)} }/>
     <DataTable response={response} columns={columns} OnewQuery={async(value)=>{ await fetch(value)}}></DataTable>
     </div>
      )}
    </Card>
  
    <ModalHider open={open_modal} user_id={{}} columns={columns} onChangeColumns={(index)=>{handleColumnVisibilityChange(index)}} onHandleClose={(value)=>{setOpenModal(value)}}></ModalHider>
    <Modal_filter_columns title={"Webmail Filter"} open={open_modal_columns} columns={columns} onHandleClose={(value)=>{setOpenModalColumns(value)}}></Modal_filter_columns>
 
    </>
    );
}