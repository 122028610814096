import * as React from 'react';
import { FormControl,Grid,Switch,Button,Modal,Box } from '@mui/material';
import './model_filter.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalHider = (props) => {
  const [open_modal,setOpenModal] = React.useState(props.open);
  const [columnStates, setColumnStates] = React.useState(
    props.columns.map((column) => column.hidden)
  );

  // useEffect to update local state when open prop changes
  React.useEffect(() => {
    setColumnStates(props.columns.map((column) => column.hidden));
    setOpenModal(props.open);
  }, [props.open,props.columns]);

  const handleClose = (event) => {
    event.preventDefault();
    // No need to use local state if using the prop directly
    // setOpenModal(false);
    // Trigger parent's onClose function or update the parent state accordingly
    props.onHandleClose(false);
    setOpenModal(false);
  };

  const handleSwitchChange=(index)=>{
    
    setColumnStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
   
    props.onChangeColumns(index);
  }

  return (
    <Modal
      open={open_modal}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width: '60%' }}>
        <h2 id="child-modal-title" className='class_title'>Setting columns</h2>
        <p id="child-modal-description"></p>
        <hr />
      
        <Grid container spacing={3}>
        {props.columns.map((item,index)=>(
      <Grid key={index} item xs={4}>
      <FormControl className="column-container">
        <label className="class_label">{item.label}</label>
        <Switch
          className="class_switch"
          id={item.id}
          checked={!columnStates[index]}
          onChange={() => handleSwitchChange(index)}
        />
      </FormControl>
    </Grid>
         )) }
          
      
        </Grid> 
        
        <hr/>
        <Grid className='myContainerFlex'  container spacing={2}>
        <Button variant="outlined" className="button-customize" size="large" color="error" onClick={handleClose}>Close</Button>
      </Grid>
      </Box>
    </Modal>
  );
};
export default ModalHider;