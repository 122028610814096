import { Typography } from "@mui/material";
import React from "react";
import MailmanForm from "../components/forms/mailman/mailmanForm";

const Mailman = () => {
  return (
    <>
      <MailmanForm />
    </>
  );
};

export default Mailman;
