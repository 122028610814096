import React, { useContext } from "react";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import SimpleLayout from "../layouts/simple";

import LoginPage from "../pages/LoginPage";
import Page404 from "../pages/Page404";
import DashboardAppPage from "../pages/DashboardAppPage";
import SlackPage from "../pages/SlackPage";
import WordpressPage from "../pages/WordpressPage";
import SettingsPage from "../pages/SettingsPage";
import Mailman from "../pages/Mailman";
import UserListTest from "../pages/UserListTest";
import { AuthContext } from "../context/auth-context";
import Protected from "../sections/auth/login/Protected";
import WebMailPage from "../pages/Webmail/WebmailPage";

// ----------------------------------------------------------------------

export default function Router() {

    const auth = useContext(AuthContext);
  
    const basename = ''; // Define el basename aquí
  
    const routes = useRoutes([
      {
        path: `${basename}/dashboard`, // Utiliza el basename en la ruta
        element: (
          <Protected isLoggedIn={auth.isLoggedIn}>
            <DashboardLayout />
          </Protected>
        ),
        children: [
          { element: <Navigate to={`${basename}/dashboard/app`} />, index: true }, // Utiliza el basename en la navegación
          { path: "app", element: <DashboardAppPage /> },
          { path: "members", element: <UserListTest /> }, //google sheet integration
          { path: "webmail", element: <WebMailPage /> }, //cpanel integration
          // { path: "slack", element: <SlackPage /> },
          //{ path: "wordpress", element: <WordpressPage /> },
          { path: "mailman", element: <Mailman /> },
          { path: "settings", element: <SettingsPage /> },
        ],
      },
      {
        path: `${basename}/login`, // Utiliza el basename en la ruta
        element: <LoginPage />,
      },
      {
        element: <SimpleLayout />,
        children: [
          { element: <Navigate to={`${basename}/dashboard/app`} />, index: true }, // Utiliza el basename en la navegación
          { path: `${basename}/404`, element: <Page404 /> }, // Utiliza el basename en la ruta
          { path: "*", element: <Navigate to={`${basename}/404`} /> }, // Utiliza el basename en la navegación
        ],
      },
      {
        path: `${basename}/*`, // Utiliza el basename en la ruta
        element: <Navigate to={`${basename}/404`} replace />, // Utiliza el basename en la navegación
      },
    ]);
  
    return routes;
  };
  




