const teams = [
  "[NT] No Team",
  "[CT] - Content Team",
  "[RT] - Research Team",
  "[ST] - Support Team",
  "[MT] - Marketing Team",
  "[DT] - Development Team",
  "[AT] - Advisory Team",
];

export default teams;
