import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DataTable from '../components/usersListComponent/datatable/datatable';
import {fetchUsersList} from '../api/users/fetchUsersList';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import ToolbarDataTable from '../components/usersListComponent/datatable/tables_components/body/toolbar_data_table';
import { Card } from '@mui/material';
import ModalFilter from '../components/usersListComponent/datatable/tables_components/filter/modal_filter';
import Modal_filter_columns from '../components/usersListComponent/datatable/tables_components/filter/modal_filter_form';
import roles from "../_mock/roles"
import teams from "../_mock/teams";
import { Helmet } from 'react-helmet-async';





export  const UserListTest = () => {

  const [columns,setColumns] = useState([
 
    { id: 'first_name',type:'string', label: 'First Name', minWidth: 100,hidden:false },
    {
      id: 'last_name',
      label: 'Last Name',
      minWidth: 170,
      align: 'right',
      type:'string',
      hidden:false
     
    },
    { id: "position", label: "Position",  type:'string', editable: false,minWidth: 180,hidden:false },
    { id: "team", label: "Team", type:'select',options:teams, editable: false, minWidth: 140,hidden:false },
    { id: "role_name", label: "Role",type:'select',options:roles, editable: false, minWidth: 250,hidden:false },
    {
      id: "create_date",
      label: "Start date",
      type:'date',
      editable: false,
      minWidth: 150,
      hidden:false
    },{
      id: "end_date",
      label: "End date",
      type:'date',
      editable: false,
      minWidth: 150,
      hidden:false
    },
    {
      id: "personal_email",
      label: "Personal Email",
      type:'string',
      editable: false,
      minWidth: 240,
      hidden:false
    },
    {
      id: "thrive_email",
      label: "Thrive Email",
      type:'string',
      editable: false,
      minWidth: 220,
      hidden:false
    }
  ]);
  const [response, setResponse] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [open_modal,setOpenModal]=useState(false);
  const [open_modal_columns,setOpenModalColumns]=useState(false);
  const fetchData = async (query={}) => {
    try {
     
        const usersData = await fetchUsersList(query);
        setResponse(usersData.response);
        
  
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
     console.log("console.log open modal ",open_modal);
    fetchData();
  }, [open_modal]);


  const handleColumnVisibilityChange = (index) => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      newColumns[index].hidden = !newColumns[index].hidden;
      return newColumns;
    });
  };
  
  const search_query= async(value)=>{
    let query={
      page:0,
	    whereSql:"u.thrive_email LIKE '%"+value+"%'"
    }
    await fetchData(query);
  }

  return (
    <>
     <Helmet>
        <title> Members Work | TAMS </title>
      </Helmet>
    <div style={{ height: 400, width: '100%' }}>
    <div>
    <Card>
      {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '650px', width: '100%'  }}>
          <CircularProgress size={80} />
        </Box>
      ) : (
      <>
          <ToolbarDataTable onModaluser={(value)=>{console.log(value)}} onOpenModal={(value)=>{ setOpenModal((value) => !value)}}  onOpenModalColumns={(value)=>{ setOpenModalColumns((value) => !value)}} count={response.total_count} onNewCategory={(value)=>{ search_query(value)} }/>
        <DataTable response={response} columns={columns} OnewQuery={async(value)=>{ await fetchData(value)}}></DataTable>
        </>
        
     )} </Card>
    </div>
    </div>
    <ModalFilter open={open_modal} user_id={{}} columns={columns} onChangeColumns={(index)=>{handleColumnVisibilityChange(index)}} onHandleClose={(value)=>{setOpenModal(value)}} ></ModalFilter>
    <Modal_filter_columns open={open_modal_columns} columns={columns} onHandleClose={(value)=>{setOpenModalColumns(value)}}></Modal_filter_columns>
    </>
  );
};

export default UserListTest;