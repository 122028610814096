import { Typography, Box, Button } from "@mui/material";
import React, { useContext } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";

const FormConfirmation = ({
  onClose,
  message,
  onClosePop,
  from,
}) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const onCloseHandler = (evt) => {
    // evt.preventDefault();
    onClose();
    auth.refresh();
    onClosePop();

    // navigate("/dashboard/user");

    if (from === "usersPage") {
      navigate("/dashboard/user");
    } else if (from === "wordpress") {
      navigate("/dashboard/wordpress");
    } else {
      navigate("/dashboard/webmail");
    }
  };

  let welcomeEmailConfirmation;
  if (message.welcomeEmailStatus) {
    if (message.welcomeEmailStatus === "success") {
      welcomeEmailConfirmation = (
        <Box textAlign={"center"}>
          <CheckCircleOutlineIcon color={"success"} />
          <Typography variant="subtitle1">{message.welcomeEmailMsg}</Typography>
        </Box>
      );
    } else {
      welcomeEmailConfirmation = (
        <Box textAlign={"center"}>
          <ErrorOutlineIcon color={"error"} />
          <Typography variant="subtitle1">{message.welcomeEmailMsg}</Typography>
        </Box>
      );
    }
  } else {
    welcomeEmailConfirmation = "";
  }
  return (
    <form onSubmit={onCloseHandler}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box textAlign={"center"}>
          <Typography variant="h5">{message.message}</Typography>
        </Box>
        {welcomeEmailConfirmation}
        <Box mt={"1rem"}>
          <Button variant="contained" color="primary" type="submit">
            {/* <Button variant="contained" color="primary" onClick={onCloseHandler}> */}
            Close
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default FormConfirmation;
