import React from "react";
import { useState, useEffect, useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Popover from "@mui/material/Popover";

const SENDER = "newsletter@strive2thrive.earth";
const SENDER_NAME = "Newsletter";

const MailmanFormTab = () => {
  const [template, setTemplate] = useState("");
  const [sender, setSender] = useState(SENDER);
  const [senderName, setSenderName] = useState(SENDER_NAME);
  const [recipient, setRecipient] = useState("");
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [check, setCheck] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const editorRef = useRef(null);

  //popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //popover end

  const handleTemplateChange = (event) => {
    let selection = event.target.value;
    setTemplate(event.target.value);

    fetchTemplateContent(templates[selection]);
  };

  const handleSenderChange = (event) => {
    setSender(event.target.value);
  };

  const handleSenderNameChange = (event) => {
    setSenderName(event.target.value);
  };

  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);

    let selection = event.target.value;
    let numberLists = lists.length;

    for (let i = 0; i <= numberLists; i++) {
      if (selection === i) {
        setSelectedList(lists[i]);
      }
    }
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleInternalCommunication = (event) => {
    setCheck(event.target.checked);
    console.log(event.target.checked);
  };

  const sendNewsletterApi = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/newsletter/send`,
        {
          method: "POST",
          // body: data,
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error While trying to send newsletter");
      }

      const responseData = await response.json(); //response from server
      setIsSubmitted(true);

      setIsSubmitting(false);

      setMessage(responseData.message);
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    const finalEmail = editorRef.current.getContent();
    event.preventDefault();
    // console.log({
    // sender,
    //   selectedList,
    //   subject,
    //   finalEmail,
    // });

    sendNewsletterApi({
      sender,
      senderName,
      recipient: selectedList,
      subject,
      text: finalEmail,
      check,
    });
    setSubject("");
    setTemplate("");
    setRecipient("");
    setText("");
    setCheck(false);
  };

  const fetchMailingList = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/newsletter/listNames`
      );
      if (!response.ok) {
        throw new Error("Something went Wrong");
      }

      const data = await response.json();

      setLists(data.lists);

      setIsLoading(false);
    } catch (error) {
      setError(error.message); //message here is the msg set when throw error.
      setIsLoading(false); //done loading with error
    }
  };

  const fetchTemplateList = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/newsletter/template`
      );

      if (!response.ok) {
        throw new Error("Something went Wrong");
      }

      const data = await response.json();

      setTemplates(data.templates);

      setIsLoading(false);
    } catch (error) {
      setError(error.message); //message here is the msg set when throw error.
      setIsLoading(false); //done loading with error
    }
  };

  const fetchTemplateContent = async (templateSelected) => {
    setIsLoading(true);
    setError(null);

    try {
      const template = {
        templateSelected: templateSelected,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/newsletter/templateContent`,
        {
          method: "POST",
          // body: template,
          body: JSON.stringify(template),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went Wrong");
      }

      const data = await response.json();

      setText(data.templates);
      setIsLoading(false);
    } catch (error) {
      setError(error.message); //message here is the msg set when throw error.
      setIsLoading(false); //done loading with error
    }
  };

  useEffect(() => {
    fetchTemplateList();
    fetchMailingList();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl sx={{ width: "49%", mb: "1rem" }}>
            <TextField
              id="from"
              label="From Email:"
              value={sender}
              onChange={handleSenderChange}
              fullWidth
              // disabled
            />
          </FormControl>
          <FormControl sx={{ width: "49%", mb: "1rem" }}>
            <TextField
              id="fromName"
              label="From Name:"
              value={senderName}
              onChange={handleSenderNameChange}
              fullWidth
              // disabled
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl sx={{ width: "49%", mb: "1rem" }}>
            <InputLabel id="recipient-list-label">Send To</InputLabel>
            <Select
              labelId="recipient-list-label"
              id="recipient-list"
              label="Mailing List"
              value={recipient}
              onChange={handleRecipientChange}
            >
              <MenuItem value="">---Select a List---</MenuItem>
              {lists.map((list, index) => (
                <MenuItem value={index} key={index}>
                  {list}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: "49%" }}>
            <InputLabel id="template-label">Template</InputLabel>
            <Select
              labelId="template-label"
              label="Template"
              id="template"
              value={template}
              onChange={handleTemplateChange}
            >
              <MenuItem value="">---Select a Template---</MenuItem>
              {templates.map((template, index) => (
                <MenuItem value={index} key={index}>
                  {template}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box pb={"1rem"} sx={{ width: "100%" }}>
          <TextField
            id="subject"
            label="Subject"
            value={subject}
            onChange={handleSubjectChange}
            fullWidth
          />
        </Box>
        <Box>
          {isSubmitting ? <LinearProgress /> : ""}

          {!isSubmitted ? (
            <Editor
              apiKey="smvmzosipoqgq7qlecle87yyv4sfzaceswyrz4prh5lvzadn"
              initialValue={text}
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  "code",
                  // "advlist autolink lists link image charmap print preview anchor",
                  // "searchreplace visualblocks code fullscreen",
                  // "insertdatetime media table paste code help wordcount",
                ],
                // plugins: "code",
                toolbar:
                  "code undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
              }}
            />
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              border={"1px solid #dde1e5"}
              borderRadius={"5px"}
              height={"25rem"}
              // sx={{ border: "1px" }}
            >
              {error ? (
                <ErrorOutlineIcon color={"error"} />
              ) : (
                <CheckCircleOutlineIcon color={"success"} size={"2rem"} />
              )}
              <Typography variant="h4">{message}</Typography>
            </Box>
          )}
        </Box>
        <Box>
          <FormGroup sx={{ width: "20%" }}>
            <FormControlLabel
              control={<Checkbox />}
              label="Internal communication"
              onChange={handleInternalCommunication}
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
          </FormGroup>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography variant="caption" sx={{ p: 1 }}>
              Check this box if you are sending email to Thrive users only, no
              external emails.
            </Typography>
          </Popover>
        </Box>
        {!isSubmitted ? (
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: "1rem" }}
            loading={isSubmitting ? true : false}
          >
            Send
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: "1rem" }}
            onClick={() => setIsSubmitted(false)}
          >
            Back
          </Button>
        )}
      </form>
    </>
  );
};

export default MailmanFormTab;
