import React from "react";
import { useState, useRef } from "react";
import Papa from "papaparse";

import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Editor } from "@tinymce/tinymce-react";

const MailmanUploadTab = () => {
  const [newListName, setNewListName] = useState("");
  const [newList, setNewList] = useState("");
  const [listUploaded, setListUploaded] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTemplate, setNewTemplate] = useState("");
  const [templateUploaded, setTemplateUploaded] = useState(false);
  const [displayTiny, setDisplayTiny] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [text, setText] = useState("");

  const editorRef = useRef(null);

  const handleNewList = (event) => {
    setNewListName(event.target.value);
  };

  const handleNewTemplate = (event) => {
    setNewTemplateName(event.target.value);
  };

  const handleTemplateFileUpload = (event) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      setDisplayTiny(true);
      setNewTemplate(event.target.result);
      setText(event.target.result);
    };
    reader.readAsText(file);
    setTemplateUploaded(true);
  };

  const handleListFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      // handle null or undefined file
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target.result;
      const parsedData = Papa.parse(csvData, { header: false });
      setNewList(parsedData.data.flat());
    };
    reader.readAsText(file);
    setListUploaded(true);
  };

  const uploadNewFiles = async (data) => {
    setIsSubmitting(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/newsletter/uploadnew`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error While trying to send newsletter");
      }

      const responseData = await response.json(); //response from server
      setIsSubmitted(true);
      setError(false);
      setIsSubmitting(false);
      setMessage(responseData.message);
    } catch (error) {
      setIsSubmitting(false);
      setError(true);
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    const finalTemplate = editorRef.current.getContent();
    event.preventDefault();

    uploadNewFiles({
      newListName,
      newList,
      newTemplateName,
      newTemplate: finalTemplate,
    });

    setNewListName("");
    setNewList("");
    setNewTemplateName("");
    setNewTemplate("");
    setText("");
    setDisplayTiny(false);
    setIsSubmitting(false);
    setListUploaded(false);
    setTemplateUploaded(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {isSubmitting && <LinearProgress />}
      {isSubmitted ? (
        <Box sx={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
          <Typography variant="h4" sx={{ color: "#788692" }}>
            {message}
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
            <Typography variant="h6" pb={"1rem"} sx={{ color: "#788692" }}>
              Upload New Files here.
            </Typography>
            <Typography variant="body2" sx={{ color: "#788692" }}>
              *Please make sure to use csv files for new list and html files for
              new template.
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              pb={"1rem"}
              sx={{
                width: "49%",
                display: "flex",
                alignContent: "center",
                alignSelf: "flex-start",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                component="label"
                size="small"
                sx={{ marginRight: "0.5rem" }}
              >
                {/* Upload File */}
                {listUploaded ? (
                  <DriveFolderUploadIcon sx={{ color: "#24F40B" }} />
                ) : (
                  <DriveFolderUploadIcon />
                )}

                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleListFileUpload}
                />
              </Button>
              <TextField
                id="subject"
                label="List Name"
                value={newListName}
                onChange={handleNewList}
                fullWidth
              />
              <Box
                sx={{ position: "absolute", top: "55px", cursor: "pointer" }}
                onClick={() => setListUploaded(false)}
              >
                <Typography variant="caption" color="#788692">
                  x remove file
                </Typography>
              </Box>
            </Box>

            <Box
              pb={"1rem"}
              sx={{
                width: "49%",
                display: "flex",
                alignContent: "center",
                alignSelf: "flex-end",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                component="label"
                size="small"
                sx={{ marginRight: "0.5rem" }}
              >
                {/* Upload File */}
                {templateUploaded ? (
                  <DriveFolderUploadIcon sx={{ color: "#24F40B" }} />
                ) : (
                  <DriveFolderUploadIcon />
                )}

                <input
                  type="file"
                  accept=".html"
                  hidden
                  onChange={handleTemplateFileUpload}
                />
              </Button>
              <TextField
                id="subject"
                label="Template Name"
                value={newTemplateName}
                onChange={handleNewTemplate}
                fullWidth
              />
              <Box
                sx={{ position: "absolute", top: "55px", cursor: "pointer" }}
                onClick={() => setTemplateUploaded(false)}
              >
                <Typography variant="caption" color="#788692">
                  x remove file
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Box sx={{ marginTop: "1rem" }}>
        {displayTiny ? (
          <Editor
            apiKey="smvmzosipoqgq7qlecle87yyv4sfzaceswyrz4prh5lvzadn"
            initialValue={text}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              height: 500,
              menubar: true,
              plugins: [
                "code",
                // "advlist autolink lists link image charmap print preview anchor",
                // "searchreplace visualblocks code fullscreen",
                // "insertdatetime media table paste code help wordcount",
              ],
              // plugins: "code",
              toolbar:
                "code undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
            }}
          />
        ) : (
          ""
        )}
      </Box>
      <Box mt={"3.5rem"}>
        {!isSubmitted ? (
          <Button variant="contained" size="large" type="submit">
            Save Files
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            onClick={() => setIsSubmitted(false)}
          >
            Upload New Files
          </Button>
        )}
      </Box>
    </form>
  );
};

export default MailmanUploadTab;
