import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";

import apiGeneral from "../../../api/apiGeneral";
import FormConfirmation from "../FormConfirmation";

const FormWrapper = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  maxWidth: "400px",
  margin: "0 auto",
});

function WpForm({
  onClose,
  onClosePop,
  title,
  fname,
  lname,
  emailAdd,
  passwordAdd,
  rolex,
  type,
  user,
}) {
  // console.log(user);
  //new user inputs
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [thriveEmail, setThriveEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [password, setPassword] = React.useState("");

  //form submittion
  const [isSubmitting, setIsSubmitting] = useState(false); // button effect on submittion
  const [submittionError, setSubmittionError] = useState(null); //picking any erros
  const [isFormSubmitted, setSubmitted] = useState(false); //form message confirmation
  const [message, setMessage] = useState(true);

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    console.log(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    console.log(event.target.value);
  };

  const handleEmailChange = (event) => {
    setThriveEmail(event.target.value);
    console.log(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    console.log(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    console.log(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmittionError(null);

    const newUSer = {
      firstName,
      lastName,
      thriveEmail,
      role,
      description,
      password,
    };

    // handling type of submittion based on action
    switch (type) {
      case "create":
        console.log("create account");
        apiGeneral(
          newUSer,
          "POST",
          `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/wordpress`,
          setIsSubmitting,
          setSubmittionError,
          setSubmitted,
          setMessage
        );
        break;
      case "edit":
        apiGeneral(
          {
            id: user.id,
            firstName: firstName === "" ? user.first_name : firstName,
            lastName: lastName === "" ? user.last_name : lastName,
            thriveEmail: thriveEmail === "" ? user.email : thriveEmail,
            role: role === "" ? user.role : role,
            description: description === "" ? user.description : description,
          },
          "POST",
          `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/wordpress/edit`,
          setIsSubmitting,
          setSubmittionError,
          setSubmitted,
          setMessage
        );
        break;

      case "suspend":
        apiGeneral(
          {
            id: user.id,
          },
          "POST",
          `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/wordpress/suspend`,
          setIsSubmitting,
          submittionError,
          setSubmitted,
          setMessage
        );
        break;

      case "delete":
        apiGeneral(
          {
            id: user.id,
          },
          "DELETE",
          `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/wordpress/delete`,
          setIsSubmitting,
          submittionError,
          setSubmitted,
          setMessage
        );
        break;

      case "reset":
        apiGeneral(
          {
            thriveEmail: thriveEmail === "" ? user.email : thriveEmail,
          },
          "POST",
          `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/wordpress/reset`,
          setIsSubmitting,
          submittionError,
          setSubmitted,
          setMessage
        );
        break;
    }
  };

  return (
    <>
      {isFormSubmitted ? (
        <FormConfirmation
          action={type}
          onClose={onClose}
          message={message}
          submitted={setSubmitted}
          onClosePop={onClosePop}
          from={"wordpress"}
        />
      ) : (
        <FormWrapper onSubmit={handleSubmit}>
          <Typography variant="h4" color={"#389382"} textAlign={"center"}>
            {title}
          </Typography>
          <Divider />
          <>
            <TextField
              id="first-name"
              label="First Name"
              defaultValue={user ? user.name.split(" ")[0] : ""}
              onChange={handleFirstNameChange}
              fullWidth
              required
              disabled={type === "create" || type === "edit" ? false : true}
            />
            <TextField
              id="last-name"
              label="Last Name"
              defaultValue={user ? user.name.split(" ")[1] : ""}
              onChange={handleLastNameChange}
              fullWidth
              required
              disabled={type === "create" || type === "edit" ? false : true}
            />
            <TextField
              id="email"
              label="Thrive Email"
              type="email"
              defaultValue={user ? user.email : ""}
              onChange={handleEmailChange}
              fullWidth
              required
              disabled={type === "create" || type === "edit" ? false : true}
            />

            <TextField
              id="password"
              label="Password"
              type="password"
              defaultValue={type === "create" ? "" : "*********"}
              onChange={handlePasswordChange}
              fullWidth
              required
              disabled={type === "create" ? false : true}
            />
            <FormControl>
              <InputLabel id="role-label">Role</InputLabel>

              <Select
                labelId="role-label"
                id="role"
                // defaultValue={rolex ? rolex : ""}
                defaultValue={user ? user.role : ""}
                onChange={handleRoleChange}
                required
                disabled={type === "create" || type === "edit" ? false : true}
              >
                <MenuItem value={"administrator"}>Administrator</MenuItem>
                <MenuItem value={"editor"}>Editor</MenuItem>
                <MenuItem value={"author"}>Author</MenuItem>
                <MenuItem value={"contributor"}>Contributer</MenuItem>
                <MenuItem value={"subscriber"}>Subscriber</MenuItem>
                <MenuItem value={"none"}>No Access</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="description"
              label="Description"
              type="text area"
              multiline
              rows={6}
              defaultValue={user ? user.description : ""}
              onChange={handleDescriptionChange}
              fullWidth
              required
              disabled={type === "create" || type === "edit" ? false : true}
            />
          </>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              color="error"
              onClick={(evt) => {
                onClose();
                onClosePop();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting ? true : false}
            >
              Confirm
            </LoadingButton>
          </Box>
        </FormWrapper>
      )}
    </>
  );
}

export default WpForm;
