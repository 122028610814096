import * as React from 'react';
import { Grid,Button,Modal,Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './model_filter.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const Modal_filter_columns=(props)=>{
  const [columns, setColumns] = React.useState(props.columns);
  const handleClose = (event) => {
    event.preventDefault();
    // No need to use local state if using the prop directly
    // setOpenModal(false);
    // Trigger parent's onClose function or update the parent state accordingly
    props.onHandleClose(false);
    //setOpenModal(false);
  };

  React.useEffect(() => {
  
  }, [columns]);
  
  const handleEndDateChange = (date) => { 
   
  };

  return(
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{...style, width: '60%' }}>
        <h2 id="child-modal-title" className='class_title'>User query</h2>
        <p id="child-modal-description"></p>
        <hr />
      
        <Grid container spacing={2}>
        {props.columns.map((item,index)=>(
      <Grid key={index} item xs={4}>
        {item.type==='string'?(<TextField
          id={item.id}
          label={item.label}
          style={{ width: '100%',marginBottom:'5px'}}
        />):(item.type==='select'?(
          <FormControl style={{ width: '100%',marginBottom:'5px'}}>
              <InputLabel id="role-label">{item.label}</InputLabel>
              <Select
                labelId={item.id}
                id={item.label}             
              >
                {item.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ):(
        <LocalizationProvider  dateAdapter={AdapterDayjs}>
          <DatePicker  style={{display:'flex'}}
                 
                  label={item.id}
                  onChange={handleEndDateChange}               
                
                  />
        </LocalizationProvider>  
        ))}
        </Grid>
        )) }
        </Grid> 
        
        <hr/>
        <Grid className='myContainerFlex'  container spacing={2}>
        <Button variant="outlined" className="button-customize" size="large" color="error" onClick={handleClose}>Close</Button>
      </Grid>
      </Box>
    </Modal>

  );

}

export default Modal_filter_columns;