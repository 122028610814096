// ----------------------------------------------------------------------

export const accounSuperadmin = {
  displayName: "Super Admin",
  email: "support@strive2thrive.earth",
  photoURL: "./assets/images/avatars/avatar_default_image.jpg",
  role: "Full Access",
};

export const accountAdmin = {
  displayName: "Admin",
  email: "support@strive2thrive.earth",
  photoURL: "./assets/images/avatars/avatar_default_image.jpg",
  role: "Limited Access",
};
