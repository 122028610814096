export const WebmailCreated = async (user_data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/email/create`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
              // Add any additional headers if required
            },
            body: JSON.stringify(user_data)
          }
      );
  
      if (!response.ok) {
        throw new Error("Something went Wrong");
      }
  
      const data = await response.json();
  
      // console.log(data);
  
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const suspendWebmail = async (user_data) => {
    try{
  
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/email/suspend`,{
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json'
                  // Add any additional headers if required
                },
                body: JSON.stringify(user_data)
              }
          );
      
          if (!response.ok) {
            throw new Error("Something went Wrong");
          }
      
          const data = await response.json();
    
      
          return data;

    }catch(error){
        console.log(error);
    }
  }

  export const unsuspendWebmail = async (user_data) => {
    try{
  
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/email/unsuspend`,{
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json'
                  // Add any additional headers if required
                },
                body: JSON.stringify(user_data)
              }
          );
      
          if (!response.ok) {
            throw new Error("Something went Wrong");
          }
      
          const data = await response.json();
    
      
          return data;

    }catch(error){
        console.log(error);
    }
  }

  export const WebMailList = async(query)=>{
    try{
  
      const response = await fetch(
          `${process.env.REACT_APP_BACKEND_SERVER_ADD}api/email/`,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
                // Add any additional headers if required
              },
              body: JSON.stringify(query)
            }
        );
    
        if (!response.ok) {
          throw new Error("Something went Wrong");
        }
    
        const data = await response.json();
  
    
        return data;

  }catch(error){
      console.log(error);
  }
  }
